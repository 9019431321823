<template>
  <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
    <template v-slot:activator="{ on, attrs }">
      <v-avatar size="38px" v-bind="attrs" v-on="on" class="mr-5">
        <v-img :src="generateAvatar()"></v-img>
      </v-avatar>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-avatar size="40px" class="ml-2">
          <v-img :src="generateAvatar()"></v-img>
        </v-avatar>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
          <span class="email text--primary mb-n1">
            {{ getName() }}
          </span>
        </div>
      </div>

      <v-divider></v-divider>

      <v-list-item link to="/admin/dashboard" v-if="this.$store.state.user.userLevel > 0">
        <v-list-item-icon class="me-2">
          <v-icon size="22">mdi-shield-crown </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Admin</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider v-if="this.$store.state.user.userLevel > 0"></v-divider>

      <!-- Profile -->
      <v-list-item link to="/dashboard/account">
        <v-list-item-icon class="me-2">
          <v-icon size="22">mdi-account-cog-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Account</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/dashboard/captures">
        <v-list-item-icon class="me-2">
          <v-icon size="22">mdi-file-image-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Captures</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/dashboard/history">
        <v-list-item-icon class="me-2">
          <v-icon size="22">mdi-history</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>History</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Download -->
      <v-list-item link to="#">
        <v-list-item-icon class="me-2">
          <v-icon size="22"> mdi-download-box-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Download</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Pricing -->
      <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22"> mdi-currency-usd </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Pricing</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- FAQ -->
      <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22"> mdi-help-circle-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>FAQ</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item link @click="logout">
        <v-list-item-icon class="me-2">
          <v-icon size="22"> mdi-logout-variant </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.commit("disconnectUser");
      this.$router.push("/");
      this.$toasted.show("Successfully logged out", {
        theme: "toasted-primary",
        type: "info",
        position: "top-center",
        duration: 2000,
        iconPack: "mdi",
        icon: "logout",
      });
    },
    getName() {
      return this.$store.state.user.email;
    },
    generateAvatar() {
      return (
        "https://eu.ui-avatars.com/api/?name=" +
        this.$store.state.user.email +
        "&background=59acef&color=fff"
      );
    },
  },
};
</script>

<style>
.email {
  font-size: 0.85em;
}
</style>

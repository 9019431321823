<template>
  <v-list-item
    class="vertical-nav-menu-link"
    v-bind="$attrs"
    active-class="bg-gradient-primary white--text primary"
  >
    <v-list-item-icon>
      <v-icon :class="{ 'alternate-icon-small': !icon }" class="">
        {{ icon || alternateIcon }}
      </v-icon>
    </v-list-item-icon>

    <v-list-item-title class="d-flex justify-space-between">
      {{ title }}
      <small>
        {{ subtitle }}
      </small>
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  data: function () {
    return {
      alternateIcon: "mdi-checkbox-blank-circle-outline",
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
    },
    icon: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.alternate-icon-small {
  font-size: 14px;
  height: 14px;
  width: 14px;
}
.vertical-nav-menu-link {
  &.v-list-item--active {
    box-shadow: 0 5px 10px -4px rgba(17, 101, 226, 0.42);
  }
}

// .v-list-item--active {
//   ::v-deep .v-icon {
//     color: white !important;
//   }
// }
</style>
